<template>
  <div class="membership">
    <div class="container-fluid membership-banner">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-5">
            <div class="content-box">
              <h2>
                {{ content.title }}
              </h2>
            </div>
          </div>
          <div class="col-md-5">
            <div class="image-wrap">
              <img src="../assets/webinar-hero.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pricing-wrapper">
      <div class="container">
        <p v-html="content.contents"></p>
      </div>
    </div>
  </div>
</template>

<script>
import errorLog from "@/errorLog";
import HomeService from "@/services/HomeService";
export default {
  name: "membership",
  created: function() {
    this.getContent();
  },
  data() {
    return {
      content: [],
      slug: this.$route.params.slug
    };
  },
  methods: {
    async getContent() {
      await HomeService.getContent({
        slug: this.slug
      })
        .then(result => {
          if (result.data.status == "success") {
            this.content = result.data.content;
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/membership.scss";
</style>
